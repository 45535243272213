import config from "../configuration/index";

$(document).ready(function () {
  // mobile menu classes
  $("#open_menu").click(showMenu);
  function showMenu() {
    $(".header_form").addClass("show");
    $(".nav_open").addClass("hide_1");
    $(".close").addClass("show");
    $(".one, .two, .three").addClass("animate");
  }
  $(".close").click(hideMenu);
  function hideMenu() {
    $(".header_form").removeClass("show");
    $(".close").removeClass("show");
    $(".nav_open").removeClass("hide_1");
    $(".gridContainer").removeClass("blur");
    $(".one, .two, .three").removeClass("animate");
  }

  // Get the hash, minus the "#", strip the leading & trailing slashes & split it by slash
  var hash = window.location.hash
    .substring(1)
    .replace(/^\/|\/$/g, "")
    .split("/");

  if (hash && hash[0] == "reset-password") {
    var token = hash[1],
      expiredMessage = "This reset password link has expired. Please try again using a different link.",
      form = $("#reset-password .reset-password"),
      errorBox = $("#reset-password .errors"),
      infoBox = $("#reset-password .return-info"),
      submit = form.children(".submit");
    $.get(config.API_ROOT + "/password/reset", { token: token }).fail(function () {
      errorBox.hide().text(expiredMessage).slideDown();
      form.hide();
    });

    $.fancybox("#reset-password", { padding: 0 });

    function enableSubmit() {
      submit.val("Reset").animate({ opacity: 1 }).prop("disabled", false);
    }

    function disableSubmit() {
      submit.val("Resetting...").animate({ opacity: 0.5 }).prop("disabled", true);
    }

    form.submit(function (e) {
      e.preventDefault();

      $("#token").val(token);
      var data = $(this).serialize();

      disableSubmit();

      $.ajax({
        type: "POST",
        url: config.API_ROOT + "/password/reset",
        xhrFields: {
          withCredentials: true,
        },
        data: data,
      })
        .done(function () {
          disableSubmit();
          errorBox.text("");
          form.hide(400);
          infoBox.text("Your password has been successfully reset. You can now login using your new password");
        })
        .fail(function (response) {
          enableSubmit();
          if (response.status === 404) {
            var responseJson = JSON.parse(response.responseText);
            errorBox.text((responseJson && responseJson.status && responseJson.status.message) || expiredMessage);
          } else {
            var errors = JSON.parse(response.responseText).meta.errors,
              errorText = "";

            for (var error in errors) {
              errorText += "\n" + errors[error];
            }

            errorBox.text(errorText);
          }
        });
    });
  } else if (hash && hash[0] == "expired-link") {
    $.fancybox("#expired-link", { padding: 0 });
  }

  var refreshSession = function () {
    $.ajax({
      type: "GET",
      url: config.API_ROOT + "/session",
      xhrFields: {
        withCredentials: true,
      },
    })
      .done(function (response) {
        var username = $(".header").find(".name");
        username.text(username.text() + response.data.fname + " " + response.data.lname + " | ");
        $(".header").find("a.demo").attr("href", config.ROOT);
        $(".login-form").hide();
        $(".header").find(".user-name").fadeIn();
      })
      .fail(function () {
        $(".header").find(".user-name").hide();
        $(".login-form").fadeIn();
      });
  };

  // Do initial session check
  refreshSession();

  $(".login-form").submit(function (event) {
    event.preventDefault();
    var errorSpan = $(this).find(".errors");
    errorSpan.text("");
    $.ajax({
      type: "POST",
      url: config.API_ROOT + "/login",
      data: $(this).serialize(),
      xhrFields: {
        withCredentials: true,
      },
    })
      .done(function () {
        errorSpan.text("");
        window.location = config.ROOT;
      })
      .fail(function (response) {
        if (response.responseText) errorSpan.text(JSON.parse(response.responseText).status.message);
        else errorSpan.text("An unexpected error occured. Please try again.");
      });
  });

  $(".logout").click(function (event) {
    event.preventDefault();
    var errorSpan = $(this).children(".errors");
    $.ajax({
      type: "GET",
      url: config.API_ROOT + "/logout",
      xhrFields: {
        withCredentials: true,
      },
    })
      .done(function () {
        errorSpan.text("");
        refreshSession();
      })
      .fail(function (response) {
        if (response.responseText) errorSpan.text(JSON.parse(response.responseText).status.message);
        else errorSpan.text("An unexpected error occured. Please try again.");
      });

    // $http.get(API_ROOT+'/logout').success(function() {
    //     $window.location = protocol+'//'+DOMAIN;
    // });
  });

  // logout = function() {
  //     $.get(config.API_ROOT+'/logout').success(function() {
  //         $window.location = config.ROOT;
  //     });
  // }

  $(".forgot-password-form").submit(function (event) {
    event.preventDefault();

    var info = $(this).find(".return-info"),
      errorSpan = $(this).find(".errors");

    $.ajax({
      type: "POST",
      url: config.API_ROOT + "/password/remind",
      data: $(this).serialize(),
      xhrFields: {
        withCredentials: true,
      },
    })
      .done(function (response) {
        errorSpan.empty();

        var responseMessage = response.status.message;

        info.text(responseMessage || "If a matching account exists, a password reset email will be sent shortly.");
      })
      .fail(function (response) {
        info.text("");

        var errors = JSON.parse(response.responseText).meta.errors;

        errorSpan.empty();
        var toAppend;

        for (var field in errors) {
          for (var error in errors[field]) {
            toAppend = "<error>" + errors[field][error] + "</error>";
            errorSpan.append(toAppend);
          }
        }
      });
  });

  if ($(".scrolltop").length) {
    $(".scrolltop").click(function () {
      $.scrollTo($($(this).attr("href")), {
        duration: 750,
      });
      return false;
    });
  }
  if ($(".fancybox").length) {
    $(".fancybox").fancybox({
      padding: 0,
    });
  }
  $(function () {
    if (window.PIE) {
      $(".ie-fix").each(function () {
        PIE.attach(this);
      });
    }
  });
});
